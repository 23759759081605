import { render, staticRenderFns } from "./stuBackSchool.vue?vue&type=template&id=62b7c3b1&scoped=true&"
import script from "./stuBackSchool.vue?vue&type=script&lang=js&"
export * from "./stuBackSchool.vue?vue&type=script&lang=js&"
import style0 from "./stuBackSchool.vue?vue&type=style&index=0&id=62b7c3b1&prod&lang=less&"
import style1 from "./stuBackSchool.vue?vue&type=style&index=1&id=62b7c3b1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b7c3b1",
  null
  
)

export default component.exports